import React, { useEffect, useState } from "react";
import {
  Nav,
  Row,
  Col,
  Tab,
  Table,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import "../delaymessage/DelayMessage.css";
import { ErrorMessage, Field, Form as FORM, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { delay } from "redux-saga/effects";
import { delayMessageSaveStart } from "../../store/slices/FineTuneSlice";

const DelayMessageEditModal = (props) => {
  const dispatch = useDispatch();
  const selectedBot = useSelector((state) => state.admin.selectedBot);
  const delayMessageSave = useSelector(
    (state) => state.fineTune.delayMessageSave
  );
  const [skipRender, setSkipRender] = useState(true);

  const handleSubmit = (values) => {
    dispatch(delayMessageSaveStart({ ...values, bot_id: selectedBot.bot_id, delay_message_id: props.modalShow.id ? props.modalShow.id : "" }));
  };

  const validationSchema = Yup.object().shape({
    delay_message: Yup.string().required("Delay Message is required")
    .test(
      "check-delay-message",
      "Delay Message should be less than 300 characters",
      (value) => {
        if (value) {
          return value.length <= 300;
        } else {
          return true;
        }
      }
    ).matches(/^[^<>]*$/, 'Invalid characters'),

  });

  useEffect(() => {
    if (
      !skipRender &&
      !delayMessageSave.loading &&
      Object.keys(delayMessageSave.data).length > 0
    ) {
      props.onHide();
    }
    setSkipRender(false);
  }, [delayMessageSave]);

  return (
    <>
      <Modal
        show={props.modalShow}
        onHide={props.onHide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-dialog-center import-webpage-modal delay-text-modal"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.modalShow.id ? "Edit Delay Message" : "Add Delay Message"}
          </Modal.Title>
          <Button
            className="modal-close"
            onClick={props.onHide}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              data-name="Layer 1"
              viewBox="0 0 24 24"
            >
              <path d="M16.061 10.061L14.122 12l1.939 1.939a1.5 1.5 0 11-2.122 2.121L12 14.121l-1.939 1.939c-.293.293-.677.439-1.061.439s-.768-.146-1.061-.439a1.5 1.5 0 010-2.121L9.878 12l-1.939-1.939A1.5 1.5 0 1110.06 7.94l1.939 1.939 1.939-1.939a1.5 1.5 0 112.121 2.121zM24 12c0 6.617-5.383 12-12 12S0 18.617 0 12 5.383 0 12 0s12 5.383 12 12zm-3 0c0-4.963-4.037-9-9-9s-9 4.037-9 9 4.038 9 9 9 9-4.037 9-9z"></path>
            </svg>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              delay_message: props.modalShow.delay_message ? props.modalShow.delay_message : "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, values, isValid }) => (
              <FORM className="onboarding-form">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Delay Message</Form.Label>
                  <Field
                    className="form-control"
                    as="textarea"
                    rows={5}
                    placeholder="Enter delay message"
                    name="delay_message"
                  />
                  <ErrorMessage
                    component={"div"}
                    name="delay_message"
                    className="errorMsg"
                  />
                </Form.Group>
                <div className="onboarding-import-webpage-modal-btn-sec">
                  <Button
                    onClick={props.onHide}
                    disabled={delayMessageSave.buttonDisable}
                    className="onboarding-cancel-btn"
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={delayMessageSave.buttonDisable || (props.modalShow.id && values.delay_message === props.modalShow.delay_message)}
                    className="onboarding-save-btn"
                    type="submit"
                  >
                    {delayMessageSave.buttonDisable
                      ? "Loading"
                      : props.modalShow.id ? "Update" : "Save"}
                  </Button>
                </div>
              </FORM>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default DelayMessageEditModal;
