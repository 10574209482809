import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Tab,
  Row,
  Col,
  Nav,
  Table,
  Dropdown,
  Image,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  fineTuneDeleteStart,
  fineTuneListStart,
  moreFineTuneListStart,
} from "../../../store/slices/FineTuneSlice";
import Skeleton from "react-loading-skeleton";
import NoDataFound from "../../Helper/NoDataFound";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

const FinetuneIndex = (props) => {
  const dispatch = useDispatch();

  const selectedBot = useSelector((state) => state.admin.selectedBot);
  const fineTuneList = useSelector((state) => state.fineTune.fineTuneList);
  const fineTuneDelete = useSelector((state) => state.fineTune.fineTuneDelete);

  useEffect(() => {
    dispatch(
      fineTuneListStart({ bot_id: selectedBot.bot_id, skip: 0, take: 12, ...props })
    );
  }, [selectedBot]);

  const getStatusName = (status) => {
    switch (status) {
      case 1:
        return "progress-badge";
      case 2:
        return "trained-badge";
      case 3:
        return "failed-badge";
      default:
        return "uploaded-badge";
    }
  };

  const handleDeleteFineTuning = (modelId) => {
    dispatch(fineTuneDeleteStart({ fine_tune_model_id: modelId }));
  };

  const fetchMoreData = () => {
    dispatch(
      moreFineTuneListStart({
        bot_id: selectedBot.bot_id,
        skip: fineTuneList.data.fine_tune_models.length,
        take: 12,
        ...props,
      })
    );
  };

  return (
    <div className="new-settings-finetune-table-sec">
      {fineTuneList.loading ? (
        <div className="onboarding-fine-tunings-files-box">
          {[...Array(4)].map((i, key) => (
            <Skeleton height={50} key={key} />
          ))}
        </div>
      ) : Object.keys(fineTuneList.data).length > 0 &&
        fineTuneList.data.fine_tune_models &&
        fineTuneList.data.fine_tune_models.length > 0 ? (
          <InfiniteScroll
            dataLength={fineTuneList.data.fine_tune_models.length}
            next={fetchMoreData}
            hasMore={
              fineTuneList.data.fine_tune_models.length <
              (props.file_type== 'docs' ? fineTuneList.data.docs_fine_tune_count : fineTuneList.data.links_fine_tune_count)
            }
            loader={[...Array(3)].map((i) => (
              <Skeleton height={80} className="mb-3" />
            ))}
          >
            <Table responsive="md" className="table-hover">
              <thead>
                <tr>
                <th>
                  File Name
                </th>
                <th>
                Created Date
                </th>
                <th>
                  Status
                </th>
                <th>
                  Action
                </th>
                </tr>
              </thead>
              <tbody>
                {fineTuneList.data.fine_tune_models.map((model, key) => (
                  <tr key={key}>
                    <td>{model.name.length <= 20 ? model.name : model.name.slice(0,10)+"..."+model.name.slice(-10)}</td>
                    <td>
                      <span>Created on:</span> {model.created_at}
                    </td>
                    <td>
                      {model.import_url && model.web_scrap_status != 2 ? (
                        <div className={getStatusName(model.web_scrap_status)}>
                          Web Scrape {model.web_scrap_status_formatted}
                        </div>
                      ) : (
                        <div className={getStatusName(model.training_status)}>
                          Training Model {model.training_status_formatted}
                        </div>
                      )}
                    </td>
                    <td className="recent-chat-menu-btn">
                      {fineTuneDelete.buttonDisable &&
                      fineTuneDelete.data.fine_tune_model_id == model.id ? (
                        <Image
                          className="btn-loader"
                          src={
                            window.location.origin +
                            "/images/loader/btn-loader.gif"
                          }
                        />
                      ) : (
                        <Dropdown>
                          <Dropdown.Toggle variant="success" id="dropdown-basic">
                            <div className="recent-chat-menu-icon-sec">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                x="0"
                                y="0"
                                enableBackground="new 0 0 512 512"
                                version="1.1"
                                viewBox="0 0 512 512"
                                xmlSpace="preserve"
                              >
                                <circle cx="458.667" cy="256" r="53.333"></circle>
                                <circle cx="256" cy="256" r="53.333"></circle>
                                <circle cx="53.333" cy="256" r="53.333"></circle>
                              </svg>
                            </div>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {model.file_type === "link" ? (
                              <Dropdown.Item
                                href={model.import_url}
                                target="_blank"
                              >
                                {/* <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  fill="#546594"
                                  height="20"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M23.271 9.419C21.72 6.893 18.192 2.655 12 2.655S2.28 6.893.729 9.419a4.908 4.908 0 000 5.162C2.28 17.107 5.808 21.345 12 21.345s9.72-4.238 11.271-6.764a4.908 4.908 0 000-5.162zm-1.705 4.115C20.234 15.7 17.219 19.345 12 19.345S3.766 15.7 2.434 13.534a2.918 2.918 0 010-3.068C3.766 8.3 6.781 4.655 12 4.655s8.234 3.641 9.566 5.811a2.918 2.918 0 010 3.068z"></path>
                                  <path d="M12 7a5 5 0 105 5 5.006 5.006 0 00-5-5zm0 8a3 3 0 113-3 3 3 0 01-3 3z"></path>
                                </svg> */}
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="#546594"
                                    x="0"
                                    y="0"
                                    enableBackground="new 0 0 512.19 512.19"
                                    version="1.1"
                                    viewBox="0 0 512.19 512.19"
                                    xmlSpace="preserve"
                                  >
                                    <circle cx="256.095" cy="256.095" r="85.333"></circle>
                                    <path d="M496.543 201.034C463.455 147.146 388.191 56.735 256.095 56.735S48.735 147.146 15.647 201.034c-20.862 33.743-20.862 76.379 0 110.123 33.088 53.888 108.352 144.299 240.448 144.299s207.36-90.411 240.448-144.299c20.862-33.744 20.862-76.38 0-110.123zM256.095 384.095c-70.692 0-128-57.308-128-128s57.308-128 128-128 128 57.308 128 128c-.071 70.663-57.337 127.929-128 128z"></path>
                                </svg>
                                View
                              </Dropdown.Item>
                            ) : (
                              <Dropdown.Item
                                download={true}
                                target="_blank"
                                href={model.file}
                              >
                                {/* <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  fill="#546594"
                                  height="20"
                                  data-name="Layer 1"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M17.974 7.146a1.028 1.028 0 01-.742-.569c-1.552-3.271-5.143-5.1-8.735-4.438A7.946 7.946 0 002.113 8.64a8.13 8.13 0 00.033 2.89c.06.309-.073.653-.346.901a5.51 5.51 0 00-1.801 4.07c0 3.032 2.467 5.5 5.5 5.5h11c4.136 0 7.5-3.364 7.5-7.5 0-3.565-2.534-6.658-6.026-7.354zM16.5 20h-11C3.57 20 2 18.43 2 16.5a3.51 3.51 0 011.146-2.59c.786-.715 1.155-1.773.963-2.763a6.142 6.142 0 01-.024-2.181c.403-2.422 2.365-4.421 4.771-4.862A6.4 6.4 0 0110.001 4a5.95 5.95 0 015.422 3.434 3.018 3.018 0 002.158 1.673 5.51 5.51 0 014.417 5.394c0 3.032-2.467 5.5-5.5 5.5zm-1.379-6.707a.999.999 0 010 1.414l-2.707 2.707a1.993 1.993 0 01-1.405.584L11 18l-.009-.002a1.993 1.993 0 01-1.405-.584l-2.707-2.707a.999.999 0 111.414-1.414L10 15v-5a1 1 0 112 0v5l1.707-1.707a.999.999 0 011.414 0z"></path>
                                </svg> */}
                                <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      fill="#546594"
                                      height="20"
                                      data-name="Layer 1"
                                      viewBox="0 0 24 24"
                                    >
                                      <path d="M17.974 7.146a1.028 1.028 0 01-.742-.569c-1.552-3.271-5.143-5.1-8.735-4.438A7.946 7.946 0 002.113 8.64a8.13 8.13 0 00.033 2.89c.06.309-.073.653-.346.901a5.51 5.51 0 00-1.801 4.07c0 3.032 2.467 5.5 5.5 5.5h11c4.136 0 7.5-3.364 7.5-7.5 0-3.565-2.534-6.658-6.026-7.354zm-2.853 7.562l-2.707 2.707a1.993 1.993 0 01-1.405.584l-.009.002-.009-.002a1.993 1.993 0 01-1.405-.584l-2.707-2.707a.999.999 0 111.414-1.414L10 15.001v-5a1 1 0 112 0v5l1.707-1.707a.999.999 0 111.414 1.414z"></path>
                                    </svg>
                                Download
                              </Dropdown.Item>
                            )}
                            {(model.training_status != 1 && model.web_scrap_status != 1) && <Dropdown.Item
                              disabled={fineTuneDelete.buttonDisable}
                              onClick={() => handleDeleteFineTuning(model.id)}
                            >
                              <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  fill="#546594"
                                  height="20"
                                  x="0"
                                  y="0"
                                  enableBackground="new 0 0 512 512"
                                  version="1.1"
                                  viewBox="0 0 512 512"
                                  xmlSpace="preserve"
                                >
                                <path d="M448 85.333h-66.133C371.66 35.703 328.002.064 277.333 0h-42.667c-50.669.064-94.327 35.703-104.533 85.333H64c-11.782 0-21.333 9.551-21.333 21.333S52.218 128 64 128h21.333v277.333C85.404 464.214 133.119 511.93 192 512h128c58.881-.07 106.596-47.786 106.667-106.667V128H448c11.782 0 21.333-9.551 21.333-21.333S459.782 85.333 448 85.333zM234.667 362.667c0 11.782-9.551 21.333-21.333 21.333-11.783 0-21.334-9.551-21.334-21.333v-128c0-11.782 9.551-21.333 21.333-21.333 11.782 0 21.333 9.551 21.333 21.333v128zm85.333 0c0 11.782-9.551 21.333-21.333 21.333-11.782 0-21.333-9.551-21.333-21.333v-128c0-11.782 9.551-21.333 21.333-21.333 11.782 0 21.333 9.551 21.333 21.333v128zM174.315 85.333c9.074-25.551 33.238-42.634 60.352-42.667h42.667c27.114.033 51.278 17.116 60.352 42.667H174.315z"></path>
                              </svg>
                              Delete
                            </Dropdown.Item>}
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </InfiniteScroll>
      ) : (
        <NoDataFound />
      )}
    </div>
  );
};

export default FinetuneIndex;
