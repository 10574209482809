import axios from "axios";

export const apiUrl = `https://cms-ai-dev.haive.online/api/user/`;
export const socketUrl = `wss://ai-dev.haive.online:3056`;

const Environment = {
    postMethod: async (action, object, baseUrl = apiUrl) => {
        const url = baseUrl + action;

        let formData = new FormData();
        // append your data
        for (var key in object)
            formData.append(key, object[key]);

        const accessToken = localStorage.getItem("token") ?
            localStorage.getItem("token")
            : sessionStorage.getItem("token") ?
                sessionStorage.getItem("token")
                : "";
        let config = { headers: { Authorization: 'Bearer ' + accessToken } };
        return await axios.post(url, formData, config);
    },

    // getMethod: async (action, object) => {
    //     let userId =
    //         localStorage.getItem("userId") !== "" &&
    //             localStorage.getItem("userId") !== null &&
    //             localStorage.getItem("userId") !== undefined
    //             ? localStorage.getItem("userId")
    //             : "";
    //     let accessToken =
    //         localStorage.getItem("accessToken") !== "" &&
    //             localStorage.getItem("accessToken") !== null &&
    //             localStorage.getItem("accessToken") !== undefined
    //             ? localStorage.getItem("accessToken")
    //             : "";

    //     const url = apiUrl + action;

    //     let formData = new FormData();

    //     // By Default Id and token

    //     formData.append("id", userId);
    //     formData.append("token", accessToken);

    //     // append your data
    //     for (var key in object) {
    //         formData.append(key, object[key]);
    //     }

    //     // By Default added device type and login type in future use

    //     formData.append("login_by", LOGIN_BY);
    //     formData.append("device_type", DEVICE_TYPE);
    //     formData.append("device_token", DEVICE_TOKEN);

    //     return await axios.get(url, formData);
    // },
};

export default Environment;
