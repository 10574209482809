import React from "react";
import { Image, Button } from "react-bootstrap";
// import "./NewUI.css";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toggleSidebar } from "../../../store/slices/CommonSlice";

const CollapseSidebar = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();

  return (
    <>
      <div className={`admin-ui-collapse-sidebar`}>
        <div className="admin-ui-sidebar-collapse">
          <div className="admin-ui-logo-sec">
            <Link className="" to="/">
              <Image
                className="admin-ui-logo"
                src={
                  window.location.origin + "/images/haive-text-logo.svg"
                }
              />
            </Link>
            <Button className="offcanvas-close" onClick={()=>dispatch(toggleSidebar())}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="#fff"
                data-name="Layer 1"
                viewBox="0 0 24 24"
              >
                <path d="M23.341 9.48l-3.501-6A5.016 5.016 0 0015.522 1H8.451a5.02 5.02 0 00-4.319 2.48l-3.501 6a5.01 5.01 0 000 5.039l3.501 6a5.016 5.016 0 004.318 2.48h7.071a5.02 5.02 0 004.319-2.48l3.5-6a5.01 5.01 0 000-5.039zm-1.729 4.031l-3.499 6a3.011 3.011 0 01-2.592 1.488H8.45a3.01 3.01 0 01-2.591-1.488l-3.5-6a3.004 3.004 0 010-3.023l3.499-6A3.011 3.011 0 018.45 3h7.071a3.01 3.01 0 012.591 1.488l3.5 6a3.004 3.004 0 010 3.023zm-5.905-3.805l-2.293 2.293 2.293 2.293a.999.999 0 11-1.414 1.414L12 13.413l-2.293 2.293a.997.997 0 01-1.414 0 .999.999 0 010-1.414l2.293-2.293-2.293-2.293a.999.999 0 111.414-1.414L12 10.585l2.293-2.293a.999.999 0 111.414 1.414z"></path>
              </svg>
            </Button>
          </div>
          <div className="admin-ui-collapse-body">
            <ul className="admin-ui-sidebar-list list-unstyled" onClick={()=>dispatch(toggleSidebar())}>
              <li>
                <Link to="/" className={`${location.pathname === "/" && "active"}`}>
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M7 0H4a4 4 0 00-4 4v3a4 4 0 004 4h3a4 4 0 004-4V4a4 4 0 00-4-4zm2 7a2 2 0 01-2 2H4a2 2 0 01-2-2V4a2 2 0 012-2h3a2 2 0 012 2zM20 0h-3a4 4 0 00-4 4v3a4 4 0 004 4h3a4 4 0 004-4V4a4 4 0 00-4-4zm2 7a2 2 0 01-2 2h-3a2 2 0 01-2-2V4a2 2 0 012-2h3a2 2 0 012 2zM7 13H4a4 4 0 00-4 4v3a4 4 0 004 4h3a4 4 0 004-4v-3a4 4 0 00-4-4zm2 7a2 2 0 01-2 2H4a2 2 0 01-2-2v-3a2 2 0 012-2h3a2 2 0 012 2zM20 13h-3a4 4 0 00-4 4v3a4 4 0 004 4h3a4 4 0 004-4v-3a4 4 0 00-4-4zm2 7a2 2 0 01-2 2h-3a2 2 0 01-2-2v-3a2 2 0 012-2h3a2 2 0 012 2z"></path>
                  </svg> */}
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="none"
                      viewBox="0 0 21 20"
                    >
                      <path
                        d="M1.243.735A2.493 2.493 0 013.017.013C4.059.009 5.079.009 6.047.009h1.54c1.324.004 2.422 1.101 2.445 2.453a127.42 127.42 0 010 4.578c-.027 1.402-1.11 2.473-2.52 2.488-.761.012-1.527.016-2.273.016C3.075 9.564.7 9.837.517 6.966c.007-.84 0-1.668 0-2.504.05-1.25-.29-2.777.726-3.727zM10.036 14.829c0 .828-.004 1.687.004 2.531.039 1.414-1.121 2.656-2.535 2.633-1.172-.027-2.438.004-3.625-.004-.934.043-1.973-.008-2.637-.73-.644-.598-.785-1.54-.73-2.399-.004-1.285-.004-2.613.008-3.922a2.465 2.465 0 011.835-2.375c1.008-.203 1.95-.062 3.082-.105.653 0 1.301 0 1.953.004 2.7.14 2.73 2.215 2.645 4.367zM19.098 11.864c2.98 2.945.832 8.156-3.367 8.129-4.172.023-6.375-5.18-3.367-8.121 1.777-1.856 4.957-1.875 6.734-.008zM10.97 6.99c-.02-1.59-.02-3.056 0-4.473A2.502 2.502 0 0113.462.009h.031a294.5 294.5 0 014.55 0c1.294-.02 2.458 1.156 2.442 2.449.008.871 0 1.726 0 2.59-.043 1.25.27 2.8-.73 3.746-1.02 1.066-2.711.664-4.031.746-.75 0-1.5-.004-2.227-.016a2.518 2.518 0 01-2.527-2.511v-.024z"
                      ></path>
                  </svg>
                  <span>DashBoard</span>
                </Link>
              </li>
              <li>
                <Link to="/bots" className={`${location.pathname === "/bots" && "active"}`}>
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    data-name="Layer 1"
                    viewBox="0 0 24 24"
                  >
                    <path d="M21 23a1 1 0 11-2 0c0-2.206-1.794-4-4-4H9c-2.206 0-4 1.794-4 4a1 1 0 11-2 0c0-3.309 2.691-6 6-6h6c3.309 0 6 2.691 6 6zm1-15.5v2c0 .827-.673 1.5-1.5 1.5H20c0 2.206-1.794 4-4 4H8c-2.206 0-4-1.794-4-4h-.5C2.673 11 2 10.327 2 9.5v-2C2 6.673 2.673 6 3.5 6H4c0-2.206 1.794-4 4-4h3V1a1 1 0 112 0v1h3c2.206 0 4 1.794 4 4h.5c.827 0 1.5.673 1.5 1.5zM18 6c0-1.103-.897-2-2-2H8c-1.103 0-2 .897-2 2v5c0 1.103.897 2 2 2h8c1.103 0 2-.897 2-2V6zM9.5 7a1.5 1.5 0 10.001 3.001A1.5 1.5 0 009.5 7zm5 0a1.5 1.5 0 10.001 3.001A1.5 1.5 0 0014.5 7z"></path>
                  </svg> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="none"
                    viewBox="0 0 21 24"
                  >
                    <path
                      d="M18.601 23.222h-15.2c-.525 0-.95-.454-.95-1.013 0-3.355 2.556-6.082 5.7-6.082h5.7c3.143 0 5.7 2.727 5.7 6.082 0 .56-.425 1.013-.95 1.013zm.475-17.14h-.475c0-2.236-1.704-4.055-3.8-4.055h-2.85V1.014c0-.56-.425-1.014-.95-1.014-.525 0-.95.454-.95 1.014v1.013h-2.85c-2.096 0-3.8 1.819-3.8 4.055h-.475c-.786 0-1.425.682-1.425 1.52V9.63c0 .838.64 1.52 1.425 1.52h.475c0 2.236 1.704 4.055 3.8 4.055h7.6c2.096 0 3.8-1.819 3.8-4.055h.475c.786 0 1.425-.682 1.425-1.52V7.603c0-.839-.64-1.521-1.425-1.521zm-9.54 3.085a1.061 1.061 0 010 1.433.918.918 0 01-.673.297.918.918 0 01-.671-.297l-.516-.55-.516.55a.918.918 0 01-.672.297.918.918 0 01-.671-.297 1.061 1.061 0 010-1.433l.516-.55-.516-.551a1.061 1.061 0 010-1.434.907.907 0 011.343 0l.516.55.516-.55a.907.907 0 011.343 0 1.061 1.061 0 010 1.434l-.516.55.516.55zm6.65 0a1.061 1.061 0 010 1.433.918.918 0 01-.672.297.918.918 0 01-.672-.297l-.516-.55-.516.55a.918.918 0 01-.671.297.918.918 0 01-.672-.297 1.061 1.061 0 010-1.433l.516-.55-.516-.551a1.061 1.061 0 010-1.434.907.907 0 011.343 0l.516.55.516-.55a.907.907 0 011.343 0 1.061 1.061 0 010 1.434l-.516.55.516.55z"
                    ></path>
                  </svg>
                  <span>Your Bots</span>
                </Link>
              </li>
              {/* <li>
                <Link to="#">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    data-name="Layer 1"
                    viewBox="0 0 24 24"
                  >
                    <path d="M19 10H7V7h1.5C10.43 7 12 5.43 12 3.5S10.43 0 8.5 0h-5C1.57 0 0 1.57 0 3.5S1.57 7 3.5 7H5v3c-2.757 0-5 2.243-5 5v4c0 2.757 2.243 5 5 5h14c2.757 0 5-2.243 5-5v-4c0-2.757-2.243-5-5-5zM2 3.5C2 2.673 2.673 2 3.5 2h5c.827 0 1.5.673 1.5 1.5S9.327 5 8.5 5h-5C2.673 5 2 4.327 2 3.5zM5 12h14c1.654 0 3 1.346 3 3v3H2v-3c0-1.654 1.346-3 3-3zm14 10H5a3.004 3.004 0 01-2.828-2h19.656A3.004 3.004 0 0119 22zM9 15a1 1 0 112 0 1 1 0 01-2 0zm-4 0a1 1 0 112 0 1 1 0 01-2 0zm8 0a1 1 0 112 0 1 1 0 01-2 0zm4 0a1 1 0 112 0 1 1 0 01-2 0z"></path>
                  </svg>
                  <span>Fine Tuning</span>
                </Link>
              </li> */}
              {/* <li>
                <Link to="/billings" className={`${location.pathname === "/billings" && "active"}`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M16 0H8a5.006 5.006 0 00-5 5v18a1 1 0 001.564.825l2.106-1.439 2.106 1.439a1 1 0 001.13 0l2.1-1.439 2.1 1.439a1 1 0 001.131 0l2.1-1.438 2.1 1.437A1 1 0 0021 23V5a5.006 5.006 0 00-5-5zm3 21.1l-1.1-.752a1 1 0 00-1.132 0l-2.1 1.439-2.1-1.439a1 1 0 00-1.131 0l-2.1 1.439-2.1-1.439a1 1 0 00-1.129 0L5 21.1V5a3 3 0 013-3h8a3 3 0 013 3z"></path>
                    <rect width="10" height="2" x="7" y="8" rx="1"></rect>
                    <rect width="8" height="2" x="7" y="12" rx="1"></rect>
                  </svg>
                  <span>Billing</span>
                </Link>
              </li> */}
              <li>
                <Link to="/settings" className={`${location.pathname === "/settings" && "active"}`}>
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 8a4 4 0 104 4 4 4 0 00-4-4zm0 6a2 2 0 112-2 2 2 0 01-2 2z"></path>
                    <path d="M21.294 13.9l-.444-.256a9.1 9.1 0 000-3.29l.444-.256a3 3 0 10-3-5.2l-.445.257A8.977 8.977 0 0015 3.513V3a3 3 0 00-6 0v.513a8.977 8.977 0 00-2.848 1.646L5.705 4.9a3 3 0 00-3 5.2l.444.256a9.1 9.1 0 000 3.29l-.444.256a3 3 0 103 5.2l.445-.257A8.977 8.977 0 009 20.487V21a3 3 0 006 0v-.513a8.977 8.977 0 002.848-1.646l.447.258a3 3 0 003-5.2zm-2.548-3.776a7.048 7.048 0 010 3.75 1 1 0 00.464 1.133l1.084.626a1 1 0 01-1 1.733l-1.086-.628a1 1 0 00-1.215.165 6.984 6.984 0 01-3.243 1.875 1 1 0 00-.751.969V21a1 1 0 01-2 0v-1.252a1 1 0 00-.751-.969A6.984 6.984 0 017.006 16.9a1 1 0 00-1.215-.165l-1.084.627a1 1 0 11-1-1.732l1.084-.626a1 1 0 00.464-1.133 7.048 7.048 0 010-3.75 1 1 0 00-.465-1.129l-1.084-.626a1 1 0 011-1.733l1.086.628A1 1 0 007.006 7.1a6.984 6.984 0 013.243-1.875A1 1 0 0011 4.252V3a1 1 0 012 0v1.252a1 1 0 00.751.969A6.984 6.984 0 0116.994 7.1a1 1 0 001.215.165l1.084-.627a1 1 0 111 1.732l-1.084.626a1 1 0 00-.463 1.129z"></path>
                  </svg> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="none"
                    viewBox="0 0 22 22"
                  >
                    <path
                      d="M19.1 11c0-.253-.01-.495-.032-.748l2.008-1.551c.432-.33.551-.946.281-1.43l-2.02-3.553a1.07 1.07 0 00-.587-.494 1.048 1.048 0 00-.762.032l-2.322 1.001c-.4-.286-.82-.539-1.263-.748L14.089.968A1.09 1.09 0 0013.02 0H8.992c-.55 0-1.015.418-1.08.968L7.6 3.509a8.158 8.158 0 00-1.263.748L4.014 3.256a1.056 1.056 0 00-1.35.462L.644 7.282a1.123 1.123 0 00.282 1.43l2.008 1.551a8.226 8.226 0 000 1.485L.926 13.299a1.12 1.12 0 00-.281 1.43l2.02 3.553c.27.484.852.682 1.35.462l2.32-1.001c.4.286.822.539 1.264.748l.314 2.541c.064.55.529.968 1.069.968h4.028a1.09 1.09 0 001.069-.968l.313-2.541a8.158 8.158 0 001.263-.748l2.322 1.001c.497.22 1.08.022 1.35-.462l2.02-3.553a1.123 1.123 0 00-.282-1.43l-2.008-1.551c.032-.253.043-.495.043-.748zm-8.056 3.85c-2.084 0-3.78-1.727-3.78-3.85s1.696-3.85 3.78-3.85c2.084 0 3.78 1.727 3.78 3.85s-1.696 3.85-3.78 3.85z"
                    ></path>
                  </svg>
                  <span>Settings</span>
                </Link>
              </li>
              <li>
                <Link to="/inbox" className={`${location.pathname === "/inbox" && "active"}`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 21 20"
                  >
                    <path
                      d="M3.427 2.93a9.998 9.998 0 0114.143 0c3.143 3.141 3.737 7.812 1.879 11.532l1.03 4.529a.822.822 0 01-.983.982l-4.531-1.03C8.377 22.232.498 17.473.498 9.999c0-2.67 1.04-5.18 2.93-7.068zm2.9 9.557h4.353a.821.821 0 100-1.642H6.326a.821.821 0 100 1.642zm0-3.336h8.344a.821.821 0 100-1.642H6.326a.821.821 0 100 1.642z"
                    ></path>
                  </svg>
                  <span>Inbox</span>
                </Link>
              </li>
              <li>
                <Link to="/subscription" className={`${location.pathname === "/subscription" && "active"}`}>
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    data-name="Layer 1"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12.836.029a12.043 12.043 0 00-9.327 3.492 12.027 12.027 0 00-3.48 9.333C.47 19.208 6.082 24 13.083 24H19c2.757 0 5-2.243 5-5v-6.66C24 5.862 19.097.454 12.836.029zM22 19c0 1.654-1.346 3-3 3h-5.917c-6.034 0-10.686-3.905-11.058-9.285a10.027 10.027 0 012.898-7.782 10.019 10.019 0 017.778-2.91C17.915 2.377 22 6.909 22 12.339v6.66zm-6-5c0 1.654-1.346 3-3 3v1a1 1 0 11-2 0v-1h-.268a3.01 3.01 0 01-2.598-1.499 1 1 0 111.73-1.001c.179.308.511.5.867.5h2.268c.552 0 1-.449 1-1a.768.768 0 00-.644-.76l-3.041-.507A2.761 2.761 0 017.999 10c0-1.654 1.346-3 3-3V6a1 1 0 112 0v1h.268c1.067 0 2.063.575 2.598 1.5a1 1 0 11-1.73 1.001 1.004 1.004 0 00-.867-.5H11c-.552 0-1 .449-1 1 0 .378.271.698.644.76l3.041.507A2.761 2.761 0 0116 14.001z"></path>
                  </svg> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="none"
                    viewBox="0 0 21 21"
                  >
                    <path
                      fillRule="evenodd"
                      d="M21 10.502c0 5.799-4.701 10.5-10.5 10.5S0 16.301 0 10.502 4.701.002 10.5.002 21 4.703 21 10.502zm-9.749-6.461a.75.75 0 00-1.5 0v1.684c-.087.004-.174.01-.26.017-.327.028-.686.09-1.018.243a1.83 1.83 0 00-.865.804c-.2.372-.28.809-.28 1.29 0 .902.468 1.55 1.027 2.015.522.435 1.194.77 1.77 1.058l.04.021c.628.314 1.148.578 1.522.89.35.291.487.553.487.862 0 .326-.055.495-.1.578a.333.333 0 01-.172.155c-.107.049-.27.088-.522.11-.251.022-.536.023-.879.023-.618 0-1.069-.097-1.34-.254-.213-.122-.333-.283-.333-.612a.75.75 0 00-1.5 0c0 .883.421 1.53 1.084 1.912.398.23.862.353 1.34.411v1.716a.75.75 0 001.5 0V15.28a6.17 6.17 0 00.258-.017c.328-.029.687-.09 1.02-.243a1.83 1.83 0 00.864-.805c.2-.371.28-.808.28-1.29 0-.902-.468-1.549-1.027-2.014-.522-.435-1.194-.771-1.769-1.059l-.042-.02c-.627-.314-1.146-.578-1.52-.89-.35-.292-.488-.553-.488-.863 0-.326.056-.495.1-.578a.333.333 0 01.173-.154c.106-.05.269-.089.52-.11.252-.023.537-.023.88-.023.568 0 1.024.131 1.312.315.273.173.361.361.361.55a.75.75 0 001.5 0c0-.812-.452-1.432-1.056-1.816a3.589 3.589 0 00-1.367-.492v-1.73z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span>Subscription</span>
                </Link>
              </li>
              {/* <li>
                <a href="https://haive.tech/" target="_blank">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    data-name="Layer 1"
                    viewBox="0 0 24 24"
                  >
                    <path d="M11.904 16a1.5 1.5 0 11-.001 3.001A1.5 1.5 0 0111.904 16zm1-2c0-.561.408-1.225.928-1.512a4.021 4.021 0 002.009-4.223c-.283-1.613-1.607-2.938-3.221-3.221a4.034 4.034 0 00-3.289.874 3.995 3.995 0 00-1.428 3.063 1 1 0 102 0 2.002 2.002 0 012.371-1.967c.787.138 1.458.81 1.596 1.596a1.995 1.995 0 01-1.004 2.125c-1.156.637-1.963 1.979-1.963 3.264a1 1 0 102 0zM24 19v-6.66C24 5.861 19.096.454 12.836.028a12.047 12.047 0 00-9.327 3.493A12.024 12.024 0 00.03 12.854C.47 19.208 6.082 24 13.084 24h5.917c2.757 0 5-2.243 5-5zM12.701 2.024C17.916 2.378 22 6.909 22 12.339v6.66c0 1.654-1.346 3-3 3h-5.917c-6.035 0-10.686-3.904-11.059-9.284a10.02 10.02 0 012.899-7.781A10.016 10.016 0 0112.7 2.023z"></path>
                  </svg>
                  <span>Support</span>
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default CollapseSidebar;
