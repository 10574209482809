import React, { useState } from "react";
import { Navbar, Container, Image, Nav, Row, Col, Accordion, NavLink } from "react-bootstrap";
import "./Landing.css";
import { Link } from 'react-router-dom';

const LandingPageIndex = (props) => {

  return (
    <>
    <div className="landing-page-sec">
      <section className="home-banner-sec">
        <header className="header-nav-center-1">
          <Navbar expand="lg">
            <Container>
              <NavLink to="/home">
                <h4>HaiVE</h4>
              </NavLink>
              <Navbar.Toggle aria-controls="basic-navbar-nav">
              <svg
              xmlns="http://www.w3.org/2000/svg"
              data-name="Layer 1"
              viewBox="0 0 24 24"
              width="30"
              height="30"
              fill="#03363d"
            >
              <path d="M18.5 1h-13A5.506 5.506 0 000 6.5v12C0 21.533 2.467 24 5.5 24h13c3.033 0 5.5-2.467 5.5-5.5v-12C24 3.467 21.533 1 18.5 1zM21 18.5c0 1.378-1.122 2.5-2.5 2.5h-13A2.503 2.503 0 013 18.5v-12C3 5.122 4.122 4 5.5 4h13C19.878 4 21 5.122 21 6.5v12zm-2-11A1.5 1.5 0 0117.5 9h-6a1.5 1.5 0 110-3h6A1.5 1.5 0 0119 7.5zm-11 0a1.5 1.5 0 11-3.001-.001A1.5 1.5 0 018 7.5zm11 5a1.5 1.5 0 01-1.5 1.5h-6a1.5 1.5 0 110-3h6a1.5 1.5 0 011.5 1.5zm-11 0a1.5 1.5 0 11-3.001-.001A1.5 1.5 0 018 12.5zm11 5a1.5 1.5 0 01-1.5 1.5h-6a1.5 1.5 0 110-3h6a1.5 1.5 0 011.5 1.5zm-11 0a1.5 1.5 0 11-3.001-.001A1.5 1.5 0 018 17.5z"></path>
            </svg>
              </Navbar.Toggle>
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto">
                  {/* <li className="nav-item">
                    <a className="nav-link active" href="#">Home</a>
                  </li> */}
                  <li className="nav-item">
                    <a className="nav-link smooth" href="#my-teams">Our Teams</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" target="_blank" href="https://blog.haive.tech/">Blog</a>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link open-header-btn" to="/onboarding">Get Started</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/onboarding" className="nav-link open-header-btn">Create Free Trial Account</Link>
                  </li>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </header>
        <Container>
          <div className="banner-caption">
            <div className="row align-items-center">
              <div className="col-md-12 col-xl-6 col-lg-6 resp-mrg-btm-md">
                <div className="banner-content">
                  <h1>
                  Supercharge your team with AI </h1>
                  <p>With state of the art Natural Language Processing and Generative AI fine tuned for your custom needs and hosted at your own hosting premises, HaiVE offers multi-modal, omnichannel virtual AI teams for Pre-Sales, On-Boarding and Product/Service Support with consistently optimal customer experience.</p>
                  <div className="banner-btn-sec">
                    <a href="#contact" className="default-btn smooth">Get custom AI Demo</a>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-xl-6 col-lg-6">
                <div className="text-center">
                  <Image
                    className="banner-img"
                    src={
                      "https://d1797qywsn1x03.cloudfront.net/img/banner-img.webp"
                    }
                    type="image/webp"
                  />
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>

      <section className="about-sec sm-padding pb-2">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-md-10 text-center">
              <div className="about-card">
                <h2>Why on-premise AI?</h2>
                <p>AI has grown from a buzz word or theoretical future to a useful tool for business now. ChatGPT, Bard and other AI tools have demonstrated and persuaded businesses to actually use AI to either scale up or replace entire teams with their virtual counterparts. But this advancement in the field has left businesses prone to uploading their sensitive data to third party famous AI services. FinTech is the first sector to understand the importance of hosting the groundbreaking AI service in their secure server thereby not conceding insightful business centric data to any other company and also owning all the IP generated from the whole process of making their company AI ready.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="benefit-sec sm-padding pt-0">
        <div className="container">
          <div className="benefit-box">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="benefit-card col-12 col-md-6 col-lg-4">
                    <div className="benefit-icon-sec">
                      <Image
                        className="benefit-icon"
                        src={
                          "https://d1797qywsn1x03.cloudfront.net/img/benefits/on-premise.webp"
                        }
                        type="image/webp"
                      />
                    </div>
                    <div className="benefit-info">
                      <h4>On-Premise</h4>
                      <p>The entire fine tuned LLM and its services will run from your own hosting infrastructure, so your security team will sleep peacefully</p>
                    </div>
                  </div>
                  <div className="benefit-card col-12 col-md-6 col-lg-4">
                    <div className="benefit-icon-sec">
                      <Image
                        className="benefit-icon"
                        src={
                          "https://d1797qywsn1x03.cloudfront.net/img/benefits/augment-or-replace.webp"
                        }
                        type="image/webp"
                      />
                    </div>
                    <div className="benefit-info">
                      <h4>Augment or Replace</h4>
                      <p>Our AI team can start by learning from the best of your human agents and then it becomes your call as to either have the AI support them or free them up for something else</p>
                    </div>
                  </div>
                  <div className="benefit-card col-12 col-md-6 col-lg-4">
                    <div className="benefit-icon-sec">
                      <Image
                        className="benefit-icon"
                        src={
                          "https://d1797qywsn1x03.cloudfront.net/img/benefits/specialized.webp"
                        }
                        type="image/webp"
                      />
                    </div>
                    <div className="benefit-info">
                      <h4>Specialized</h4>
                      <p>While most famous AI firms make their bots to be generally intelligent, we can get down and dirty with your exact role requirements and train the bot for it</p>
                    </div>
                  </div>
                  <div className="benefit-card col-12 col-md-6 col-lg-4">
                    <div className="benefit-icon-sec">
                      <Image
                        className="benefit-icon"
                        src={
                          "https://d1797qywsn1x03.cloudfront.net/img/benefits/your-own-ip.webp"
                        }
                        type="image/webp"
                      />
                    </div>
                    <div className="benefit-info">
                      <h4>Your own IP</h4>
                      <p>All the conversations that happen and the IP thus generated is completely yours. No external company will ever benefit off your processes</p>
                    </div>
                  </div>


                  <div className="benefit-card col-12 col-md-6 col-lg-4">
                    <div className="benefit-icon-sec">
                      <Image
                        className="benefit-icon"
                        src={
                          "https://d1797qywsn1x03.cloudfront.net/img/benefits/zendesk-freshworks-integrated.webp"
                        }
                        type="image/webp"
                      />
                    </div>
                    <div className="benefit-info">
                      <h4>Zendesk & Freshworks Integrated</h4>
                      <p>Bring your own integrations - our AI bot team can be omnichannel and communicate with your audience through your favourite tools, even email and calls</p>
                    </div>
                  </div>

                  <div className="benefit-card col-12 col-md-6 col-lg-4">
                    <div className="benefit-icon-sec">
                      <Image
                        className="benefit-icon"
                        src={
                          "https://d1797qywsn1x03.cloudfront.net/img/benefits/proficiency-updation.webp"
                        }
                        type="image/webp"
                      />
                    </div>
                    <div className="benefit-info">
                      <h4>Proficiency Updation</h4>
                      <p>Our team will work with yours, on-demand as a white glove R&D team to update the AI as your business grows and evolves with the new virtual team</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="quotes-sec sm-padding">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-md-7">
              <div className="quotes-card">
                <p>“ Your choice of tools - Zendesk or Freshworks or Email or Calls, our latest and greatest AI, hosted at your own infrastructure. With HaiVE You are always in control of the revolution ”</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="meet-the-team-sec sm-padding" id="my-teams">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-md-8 col-lg-9 text-center">
              <div className="section-title">
                <h2>Meet the Team</h2>
              </div>
            </div>
            <div className="meet-the-team-box">
              <div className="row justify-content-md-center">
              <div className="col-md-6 col-xl-4 col-lg-4 resp-mrg-btm-md">
                  <div className="meet-the-team-card">
                    <div className="meet-the-team-img-sec">
                      <Image
                        className="team-img"
                        src={
                          window.location.origin + "/images/aj-image.jpeg"
                        }
                        type="image/jpeg"
                      />
                    </div>
                    <div className="meet-the-team-info">
                      <div className="meet-the-team-content">
                        <h4>Arjun Reddy</h4>
                        <h6>Co-Founder</h6>
                        <p>Co-Founder & CEO of AI Venture Factory Talks about #nft, #nftgaming, #blockchain, #cryptocurrency, and #startupinvesting</p>
                      </div>
                      <div className="team-social-sec">
                        <ul>
                          <li><a href="https://www.linkedin.com/in/junafinity/" target="_blank">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="#fff"
                              version="1.1"
                              viewBox="0 0 310 310"
                              xmlSpace="preserve"
                            >
                              <g>
                                <path d="M72.16 99.73H9.927a5 5 0 00-5 5v199.928a5 5 0 005 5H72.16a5 5 0 005-5V104.73a5 5 0 00-5-5z"></path>
                                <path d="M41.066.341C18.422.341 0 18.743 0 41.362 0 63.991 18.422 82.4 41.066 82.4c22.626 0 41.033-18.41 41.033-41.038C82.1 18.743 63.692.341 41.066.341z"></path>
                                <path d="M230.454 94.761c-24.995 0-43.472 10.745-54.679 22.954V104.73a5 5 0 00-5-5h-59.599a5 5 0 00-5 5v199.928a5 5 0 005 5h62.097a5 5 0 005-5V205.74c0-33.333 9.054-46.319 32.29-46.319 25.306 0 27.317 20.818 27.317 48.034v97.204a5 5 0 005 5H305a5 5 0 005-5V194.995c0-49.565-9.451-100.234-79.546-100.234z"></path>
                              </g>
                            </svg>
                          </a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-xl-4 col-lg-4 resp-mrg-btm-md">
                  <div className="meet-the-team-card">
                    <div className="meet-the-team-img-sec">
                      <Image
                        className="team-img"
                        src={
                          "https://d1797qywsn1x03.cloudfront.net/img/deepika.webp"
                        }
                        type="image/webp"
                      />
                    </div>
                    <div className="meet-the-team-info">
                    <div className="meet-the-team-content">
                      <h4>Deepika Loganathan</h4>
                      <h6>Co-Founder</h6>
                      <p>Product / Business Strategist/ Investor (Fractional RE and NFT'S)/Founder</p>
                    </div>
                    <div className="team-social-sec">
                      <ul className="list-unstyled">
                        <li><a href="https://www.linkedin.com/in/deepika-loganathan-5219a34b/" target="_blank">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="#fff"
                            version="1.1"
                            viewBox="0 0 310 310"
                            xmlSpace="preserve"
                          >
                            <g>
                              <path d="M72.16 99.73H9.927a5 5 0 00-5 5v199.928a5 5 0 005 5H72.16a5 5 0 005-5V104.73a5 5 0 00-5-5z"></path>
                              <path d="M41.066.341C18.422.341 0 18.743 0 41.362 0 63.991 18.422 82.4 41.066 82.4c22.626 0 41.033-18.41 41.033-41.038C82.1 18.743 63.692.341 41.066.341z"></path>
                              <path d="M230.454 94.761c-24.995 0-43.472 10.745-54.679 22.954V104.73a5 5 0 00-5-5h-59.599a5 5 0 00-5 5v199.928a5 5 0 005 5h62.097a5 5 0 005-5V205.74c0-33.333 9.054-46.319 32.29-46.319 25.306 0 27.317 20.818 27.317 48.034v97.204a5 5 0 005 5H305a5 5 0 005-5V194.995c0-49.565-9.451-100.234-79.546-100.234z"></path>
                            </g>
                          </svg>
                        </a></li>
                      </ul>
                    </div>
                  </div>
                  </div>
                </div>
                <div className="col-md-6 col-xl-4 col-lg-4">
                  <div className="meet-the-team-card">
                    <div className="meet-the-team-img-sec">
                      <Image
                        className="team-img"
                        src={
                          "https://d1797qywsn1x03.cloudfront.net/img/aravinth.webp"
                        }
                        type="image/webp"
                      />
                    </div>
                    <div className="meet-the-team-info">
                      <div className="meet-the-team-content">
                        <h4>Aravinth Ramesh</h4>
                        <h6>Co-Founder</h6>
                        <p>CEO at CodeGama | Blockchain | Artificial Intelligence | Metaverse Consultant | Tech Entrepreneur</p>
                      </div>
                      <div className="team-social-sec">
                        <ul>
                          <li><a href="https://www.linkedin.com/in/aravinthramesh/" target="_blank">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="#fff"
                              version="1.1"
                              viewBox="0 0 310 310"
                              xmlSpace="preserve"
                            >
                              <g>
                                <path d="M72.16 99.73H9.927a5 5 0 00-5 5v199.928a5 5 0 005 5H72.16a5 5 0 005-5V104.73a5 5 0 00-5-5z"></path>
                                <path d="M41.066.341C18.422.341 0 18.743 0 41.362 0 63.991 18.422 82.4 41.066 82.4c22.626 0 41.033-18.41 41.033-41.038C82.1 18.743 63.692.341 41.066.341z"></path>
                                <path d="M230.454 94.761c-24.995 0-43.472 10.745-54.679 22.954V104.73a5 5 0 00-5-5h-59.599a5 5 0 00-5 5v199.928a5 5 0 005 5h62.097a5 5 0 005-5V205.74c0-33.333 9.054-46.319 32.29-46.319 25.306 0 27.317 20.818 27.317 48.034v97.204a5 5 0 005 5H305a5 5 0 005-5V194.995c0-49.565-9.451-100.234-79.546-100.234z"></path>
                              </g>
                            </svg>
                          </a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="cta-sec sm-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="cta-card">
                <p>Where AI powers chat, and human touch provides the heart, Haive delivers nonstop support for a customer experience set apart.</p>
                <div className="cta-btn-sec">
                  <a href="#contact" className="white-btn-1">Take Demo</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faq-sec sm-padding">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-md-8 col-lg-9 text-center">
              <div className="section-title">
                <h4>Frequently Asked Questions About<br></br> Chat Support Services</h4>
              </div>
            </div>
          </div>
          <div className="faq-box">
            <div className="row justify-content-md-center">
              <div className="col-md-8">
                  <Accordion defaultActiveKey="0" className="faq-accordion">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>What is Haive's Customizable Chat API Agent, and how can it benefit my business?</Accordion.Header>
                      <Accordion.Body>
                      Haive's Customizable Chat API Agent is a powerful tool that enables businesses to offer personalized and seamless customer support across multiple channels. It can help increase customer satisfaction, boost conversions, and improve overall business performance.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Can I customize the chat interface to match my website's branding?</Accordion.Header>
                      <Accordion.Body>
                      Yes, Haive's Customizable Chat API Agent allows for full customization of the chat interface, including colors, fonts, logos, and more. This ensures that the chat experience seamlessly integrates with your website's branding.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>What channels does Haive's Customizable Chat API Agent support?</Accordion.Header>
                      <Accordion.Body>
                      Haive's Customizable Chat API Agent supports multiple channels, including your website, social media platforms, messaging apps, and more. This enables you to engage with your customers on the platforms they prefer.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>Is Haive's Customizable Chat API Agent easy to integrate with my existing systems?</Accordion.Header>
                      <Accordion.Body>
                      Yes, Haive's Customizable Chat API Agent is designed to seamlessly integrate with your existing systems, making it easy to set up and start using.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>What level of customer support does Haive offer for its Customizable Chat API Agent?</Accordion.Header>
                      <Accordion.Body>
                        Haive offers dedicated human support to ensure that your customers receive the best possible experience. Our team is available 24/7 to help with any issues or questions you may have.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>Can I track the performance of my chatbot using Haive's Customizable Chat API Agent?</Accordion.Header>
                      <Accordion.Body>
                      Yes, Haive's Customizable Chat API Agent includes a powerful analytics dashboard that provides real-time insights into customer engagement and behavior. This enables you to track the performance of your chatbot and make data-driven decisions to improve customer support.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="contact-us-sec" id="contact">
    <div class="container">
      <div class="row justify-content-md-center">
        <div class="col-md-8 col-lg-9 text-center">
          <div class="section-title">
            <h4 class="white-color">Contact Us</h4>
          </div>
        </div>
      </div>
      <div class="contact-us-box">
        <div class="row justify-content-md-center align-items-center margin-top-xl">
          <div class="col-md-12 col-xl-6 col-lg-6 resp-mrg-btm-md">
              <div class="contact-us-img-sec">
                <Image
                      className="contact-us-img"
                      src={
                        "https://haive-tech.s3.amazonaws.com/img/contact-us-img.png"
                      }
                      type="image/png"
                    />
              </div>
          </div>
          <div class="col-md-12 col-xl-5 col-lg-5">
            <div class="contact-us-form-box">
              <h3>Send a Message</h3>
              <form class="contact-us-form" action="src/index.php" method="post">
                <div class="mb-3">
                  <label class="form-label">Name</label>
                  <input name="name" id="name" type="text" class="form-control" required/>
                </div>
                <div class="mb-3">
                  <label class="form-label">Email</label>
                  <input type="email" name="email" id="email" class="form-control" required/>
                </div>
                <div class="mb-3">
                  <label class="form-label">Phone Number</label>
                  <input type="text" name="phone" id="phone" class="form-control" required/>
                </div>
                <div class="mb-3">
                  <label for="exampleFormControlTextarea1" class="form-label">Message</label>
                  <textarea name="message" class="form-control" id="message" rows="3"
                    required></textarea>
                </div>
                <div class="contact-us-submit-btn-sec">
                  <Link class="default-btn" to="/feedback-success">
                    Submit
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

      <footer className="footer-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-xl-4  col-lg-4 resp-mrg-btm-md">
              <div className="footer-about-sec">
                <a href="#" className="footer-logo">
                  <h4>HaiVE</h4>
                </a>
                <p>For any questions please email 
                  <a href="mailto:info@haive.tech">info@haive.tech</a></p>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-xl-8 col-lg-8">
              <div className="footer-grid-box">
                <div className="footer-widget">
                  <h3>Catch us at our socials</h3>
                  <ul className="list-unstyled footer-social-link">
                    <li>
                      <a href="https://www.facebook.com/haivetech/" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" fill="#707070"
                          width="30" height="30">
                          <path
                            d="M15.12 5.32H17V2.14A26.11 26.11 0 0014.26 2c-2.72 0-4.58 1.66-4.58 4.7v2.62H6.61v3.56h3.07V22h3.68v-9.12h3.06l.46-3.56h-3.52V7.05c0-1.05.28-1.73 1.76-1.73z">
                          </path>
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/haivetech/" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" fill="#707070"
                          width="30" height="30">
                          <path
                            d="M12 9.52A2.48 2.48 0 1014.48 12 2.48 2.48 0 0012 9.52zm9.93-2.45a6.53 6.53 0 00-.42-2.26 4 4 0 00-2.32-2.32 6.53 6.53 0 00-2.26-.42C15.64 2 15.26 2 12 2s-3.64 0-4.93.07a6.53 6.53 0 00-2.26.42 4 4 0 00-2.32 2.32 6.53 6.53 0 00-.42 2.26C2 8.36 2 8.74 2 12s0 3.64.07 4.93a6.86 6.86 0 00.42 2.27 3.94 3.94 0 00.91 1.4 3.89 3.89 0 001.41.91 6.53 6.53 0 002.26.42C8.36 22 8.74 22 12 22s3.64 0 4.93-.07a6.53 6.53 0 002.26-.42 3.89 3.89 0 001.41-.91 3.94 3.94 0 00.91-1.4 6.6 6.6 0 00.42-2.27C22 15.64 22 15.26 22 12s0-3.64-.07-4.93zm-2.54 8a5.73 5.73 0 01-.39 1.8A3.86 3.86 0 0116.87 19a5.73 5.73 0 01-1.81.35H8.94A5.73 5.73 0 017.13 19a3.51 3.51 0 01-1.31-.86A3.51 3.51 0 015 16.87a5.49 5.49 0 01-.34-1.81V12 8.94A5.49 5.49 0 015 7.13a3.51 3.51 0 01.86-1.31A3.59 3.59 0 017.13 5a5.73 5.73 0 011.81-.35h6.12a5.73 5.73 0 011.81.35 3.51 3.51 0 011.31.86A3.51 3.51 0 0119 7.13a5.73 5.73 0 01.35 1.81V12c0 2.06.07 2.27.04 3.06zm-1.6-7.44a2.38 2.38 0 00-1.41-1.41A4 4 0 0015 6H9a4 4 0 00-1.38.26 2.38 2.38 0 00-1.41 1.36A4.27 4.27 0 006 9v6a4.27 4.27 0 00.26 1.38 2.38 2.38 0 001.41 1.41 4.27 4.27 0 001.33.26h6a4 4 0 001.38-.26 2.38 2.38 0 001.41-1.41 4 4 0 00.26-1.38v-3-3a3.78 3.78 0 00-.26-1.38zM12 15.82A3.81 3.81 0 018.19 12 3.82 3.82 0 1112 15.82zm4-6.89a.9.9 0 010-1.79.9.9 0 010 1.79z">
                          </path>
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/HaiveTech" target="_blank">
                        {/* <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" fill="#707070"
                          width="30" height="30">
                          <path
                            d="M22 5.8a8.49 8.49 0 01-2.36.64 4.13 4.13 0 001.81-2.27 8.21 8.21 0 01-2.61 1 4.1 4.1 0 00-7 3.74 11.64 11.64 0 01-8.45-4.29 4.16 4.16 0 00-.55 2.07 4.09 4.09 0 001.82 3.41 4.05 4.05 0 01-1.86-.51v.05a4.1 4.1 0 003.3 4 3.93 3.93 0 01-1.1.17 4.9 4.9 0 01-.77-.07 4.11 4.11 0 003.83 2.84A8.22 8.22 0 013 18.34a7.93 7.93 0 01-1-.06 11.57 11.57 0 006.29 1.85A11.59 11.59 0 0020 8.45v-.53a8.43 8.43 0 002-2.12z">
                          </path>
                        </svg> */}
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1226.37 1226.37" fill="#707070"
                          width="24" height="24">
                          <path d="M727.348 519.284L1174.075 0h-105.86L680.322 450.887 370.513 0H13.185l468.492 681.821L13.185 1226.37h105.866l409.625-476.152 327.181 476.152h357.328L727.322 519.284zM582.35 687.828l-47.468-67.894-377.686-540.24H319.8l304.797 435.991 47.468 67.894 396.2 566.721H905.661L582.35 687.854z"></path>
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/haivetech/" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" fill="#707070"
                          width="30" height="30">
                          <path
                            d="M20.47 2H3.53a1.45 1.45 0 00-1.47 1.43v17.14A1.45 1.45 0 003.53 22h16.94a1.45 1.45 0 001.47-1.43V3.43A1.45 1.45 0 0020.47 2zM8.09 18.74h-3v-9h3zM6.59 8.48a1.56 1.56 0 110-3.12 1.57 1.57 0 110 3.12zm12.32 10.26h-3v-4.83c0-1.21-.43-2-1.52-2A1.65 1.65 0 0012.85 13a2 2 0 00-.1.73v5h-3v-9h3V11a3 3 0 012.71-1.5c2 0 3.45 1.29 3.45 4.06z">
                          </path>
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="footer-widget">
                  <h3>Our Address</h3>
                  <p>No. 2 Venture Drive, #09-24 <br></br>Vision Exchange, Singapore 608526</p>
                </div>
              </div>
            </div>
          </div>
          <div className="copyrights-box">
            <div className="row">
              <div className="col-md-12 col-xl-12 col-lg-12">
                <p>Copyright 2023 Haive. All Rights Reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
    </>
  );
};

export default LandingPageIndex;
