import React, { useState, useEffect } from "react";
import {
  Navbar,
  Container,
  Image,
  Nav,
  Dropdown,
  Button,
  Tab,
  Row,
  Col,
} from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  currrentSubscriptionPlanStart,
  generateStripeIntentStart,
  subscriptionPlansStart,
} from "../../store/slices/SubscriptionSlice";
import { withTranslation, useTranslation } from "react-multi-lang";
import NoDataFound from "../Helper/NoDataFound";
import Skeleton from "react-loading-skeleton";
import EditAddressModal from "./EditAddressModal";
import UpcomingBills from "../Dashboard/UpcomingBills";
import { getSuccessNotificationMessage } from "../Helper/NotificationMessage";
import SubscriptionCard from "../Dashboard/SubscriptionCard";

const SubscriptionIndex = (props) => {
  
  const t = useTranslation("subscriptions");
  const dispatch = useDispatch();
  const [skipRender, setSkipRender] = useState(true);
  const navigate = useNavigate();
  const [editAddress, setEditAddress] = useState(false);

  const closeEditAddressModal = () => {
    setEditAddress(false);
  };

  const subscriptionPlans = useSelector(
    (state) => state.subscription.subscriptionPlans
  );
  const generateStripeIntent = useSelector(
    (state) => state.subscription.generateStripeIntent
  );
  const currentSubscription = useSelector(
    (state) => state.subscription.currentSubscription
  );

  useEffect(() => {
    dispatch(subscriptionPlansStart());
    dispatch(currrentSubscriptionPlanStart());
  }, []);

  const handleStripeIntent = (plan, type) => {
    if (plan) {
      dispatch(
        generateStripeIntentStart({
          type: type == "monthly" ? 1 : 2,
          subscription_plan_unique_id: plan.subscription_plan_unique_id,
          subscription_plan: plan,
        })
      );
    }
  };

  useEffect(() => {
    if (
      !skipRender &&
      !generateStripeIntent.loading &&
      Object.keys(generateStripeIntent.data).length > 0
    ) {
      localStorage.setItem("payment_page","subscription");
      if (generateStripeIntent.data.is_needs_to_pay) {
        navigate("/subscription-payment");
      } else {
        getSuccessNotificationMessage(
          "You have subscribed to free subscription successfully"
        );
        navigate("/billings");
      }
    }
    setSkipRender(false);
  }, [generateStripeIntent]);

  return (
    <>
      <div className="admin-ui-body-sec">
        <div className="my-subscription-sec">
          {currentSubscription.loading ? (
              <div className="onboarding-body-sec">
                <Row className="justify-content-md-center">
                  <Col md={12}>
                    <div className="mb-3 flex flex-row justfiy-between">
                      <Row>
                        <Col md={9}>
                          <Skeleton count={1} height={10} width={200} />
                          <Skeleton count={1} height={10} width={250} />
                        </Col>
                        <Col md={3} style={{ display:"flex", gap:"1em" }}>
                          <Skeleton count={1} height={40} width={150} />
                          <Skeleton count={1} height={40} width={150} />
                        </Col>
                      </Row>
                    </div>
                    <Row>
                    <div className="my-subscription-body-info">
                      <div className="my-subscription-body-info-list-box">
                        {[...Array(5)].map((i, key) => (
                            <Skeleton key={key} height={80} />
                        ))}
                      </div>
                    </div>
                    </Row>
                  </Col>
                </Row>
              </div>
          ) : Object.keys(currentSubscription.data).length > 0 &&
            Object.keys(currentSubscription.data.current_plan).length > 0 ? (
            <div className="my-subscription-header-sec">
              <div className="my-subscription-header-box">
                <div className="my-subscription-header-info">
                  <div className="my-subscription-header-info-left-sec">
                    <h3>{t("current.heading")} ({currentSubscription.data.current_plan.plan_name})</h3>
                    <p>{t("current.message")}</p>
                  </div>
                  <div className="my-subscription-header-info-right-sec">
                    <div className="my-subscription-header-action-btn-sec">
                      <Link to="https://www.haive.tech/#contact" target="_blank" className="contact-us-btn">
                        {/* <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 21 22"
                        >
                          <path
                            stroke="#048A89"
                            strokeWidth="1.7"
                            d="M17.354 13.263a2.453 2.453 0 00-3.39.005l-1.509 1.271c-2.532-1.074-4.334-2.88-5.488-5.498l1.268-1.502a2.453 2.453 0 00.004-3.388s-1.466-1.905-1.49-1.93c-.955-.956-2.512-.955-3.426-.037l-.91.794C1.502 3.888 1 5.118 1 6.442 1 12.485 9.017 20.5 15.062 20.5a4.871 4.871 0 003.5-1.45l.723-.832a2.454 2.454 0 000-3.463c-.024-.025-1.93-1.49-1.93-1.49l-.002-.002z"
                          ></path>
                        </svg> */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="none"
                          viewBox="0 0 29 29"
                        >
                          <path
                            fill="#048A89"
                            d="M27.906 20.265c-.036-.038-2.95-2.28-2.95-2.28a3.752 3.752 0 00-5.184.009l-2.3 1.944a15.468 15.468 0 01-8.396-8.407l1.936-2.3a3.753 3.753 0 00.006-5.181S8.78 1.14 8.74 1.103a3.728 3.728 0 00-5.237-.056l-1.39 1.21c-9.347 9.937 14.766 34.063 24.692 24.574l1.104-1.271a3.753 3.753 0 00-.003-5.295z"
                          ></path>
                        </svg>
                        {t("contact_us")}
                      </Link>
                      <Link to="/billings" className="billing-btn">
                        {/* <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="#fff"
                          viewBox="0 0 24 24"
                        >
                          <path d="M16 0H8a5.006 5.006 0 00-5 5v18a1 1 0 001.564.825l2.106-1.439 2.106 1.439a1 1 0 001.13 0l2.1-1.439 2.1 1.439a1 1 0 001.131 0l2.1-1.438 2.1 1.437A1 1 0 0021 23V5a5.006 5.006 0 00-5-5zm3 21.1l-1.1-.752a1 1 0 00-1.132 0l-2.1 1.439-2.1-1.439a1 1 0 00-1.131 0l-2.1 1.439-2.1-1.439a1 1 0 00-1.129 0L5 21.1V5a3 3 0 013-3h8a3 3 0 013 3z"></path>
                          <rect width="10" height="2" x="7" y="8" rx="1"></rect>
                          <rect width="8" height="2" x="7" y="12" rx="1"></rect>
                        </svg> */}
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="none"
                            viewBox="0 0 12 17"
                          >
                            <path
                              fill="#fff"
                              d="M3.333.5A3.337 3.337 0 000 3.833v12a.667.667 0 001.043.55l1.404-.96 1.404.96a.667.667 0 00.753 0l1.4-.96 1.4.96a.667.667 0 00.754 0l1.4-.958 1.4.957A.666.666 0 0012 15.834v-12A3.337 3.337 0 008.667.5H3.333zm4 9.333h-4a.667.667 0 110-1.333h4a.667.667 0 110 1.333zm2-3.333a.667.667 0 01-.666.667H3.333a.667.667 0 010-1.334h5.334a.667.667 0 01.666.667z"
                            ></path>
                          </svg>
                        {t("billing")}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="my-subscription-body-info">
                  <div className="my-subscription-body-info-list-box">
                    <div className="my-subscription-body-info-list-card">
                      <div className="my-subscription-body-info-list-icon-sec">
                        {/* <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="#03363d"
                          data-name="Layer 1"
                          viewBox="0 0 24 24"
                        >
                          <path d="M12.836.029a12.043 12.043 0 00-9.327 3.492 12.027 12.027 0 00-3.48 9.333C.47 19.208 6.082 24 13.083 24H19c2.757 0 5-2.243 5-5v-6.66C24 5.862 19.097.454 12.836.029zM22 19c0 1.654-1.346 3-3 3h-5.917c-6.034 0-10.686-3.905-11.058-9.285a10.027 10.027 0 012.898-7.782 10.019 10.019 0 017.778-2.91C17.915 2.377 22 6.909 22 12.339v6.66zm-6-5c0 1.654-1.346 3-3 3v1a1 1 0 11-2 0v-1h-.268a3.01 3.01 0 01-2.598-1.499 1 1 0 111.73-1.001c.179.308.511.5.867.5h2.268c.552 0 1-.449 1-1a.768.768 0 00-.644-.76l-3.041-.507A2.761 2.761 0 017.999 10c0-1.654 1.346-3 3-3V6a1 1 0 112 0v1h.268c1.067 0 2.063.575 2.598 1.5a1 1 0 11-1.73 1.001 1.004 1.004 0 00-.867-.5H11c-.552 0-1 .449-1 1 0 .378.271.698.644.76l3.041.507A2.761 2.761 0 0116 14.001z"></path>
                        </svg> */}
                        {/* <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fill="#03363D"
                            fillRule="evenodd"
                            d="M20 10c0 5.523-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0s10 4.477 10 10zm-9.25-6.154a.75.75 0 00-1.5 0v1.57a5.95 5.95 0 00-.214.015c-.314.027-.66.086-.982.234a1.778 1.778 0 00-.84.782c-.195.36-.271.783-.271 1.245 0 .874.453 1.499.99 1.947.501.417 1.144.738 1.691 1.011l.041.02c.598.3 1.09.55 1.442.844.328.273.451.513.451.794 0 .307-.052.461-.091.533a.282.282 0 01-.148.132c-.095.044-.246.081-.484.102-.238.02-.507.021-.834.021-.587 0-1.008-.092-1.259-.236-.192-.111-.3-.252-.3-.552a.75.75 0 00-1.5 0c0 .853.41 1.482 1.052 1.852.374.216.81.334 1.257.391v1.603a.75.75 0 001.5 0v-1.57c.072-.004.143-.008.214-.015.314-.027.66-.086.982-.234.34-.156.638-.407.84-.782.195-.36.271-.783.271-1.245 0-.873-.453-1.499-.99-1.947-.501-.417-1.144-.738-1.691-1.011l-.041-.02c-.598-.3-1.09-.55-1.442-.844-.328-.273-.451-.513-.451-.794 0-.307.052-.461.091-.533a.282.282 0 01.148-.132c.096-.044.246-.081.484-.102.238-.02.507-.021.835-.021.535 0 .962.124 1.23.294.252.16.327.33.327.494a.75.75 0 101.5 0c0-.788-.44-1.389-1.022-1.76a3.437 3.437 0 00-1.285-.468V3.846z"
                            clipRule="evenodd"
                          ></path>
                        </svg> */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fill="#03363D"
                            fillRule="evenodd"
                            d="M20 10c0 5.523-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0s10 4.477 10 10zM8.513 11.722H9c1.907 0 3.48-1.344 3.653-3.055h.68c.367 0 .667-.275.667-.611 0-.337-.3-.612-.667-.612h-.806a3.267 3.267 0 00-.7-1.222h1.506c.367 0 .667-.275.667-.61 0-.337-.3-.612-.667-.612H6.667C6.3 5 6 5.275 6 5.611c0 .336.3.611.667.611H9c.933 0 1.733.501 2.107 1.222h-4.44C6.3 7.444 6 7.72 6 8.056c0 .336.3.61.667.61h4.64C11.147 9.707 10.173 10.5 9 10.5H7.62c-.527 0-.953.391-.953.874 0 .22.093.44.266.599l4.02 3.838c.127.122.3.189.48.189.587 0 .887-.642.48-1.033l-3.4-3.245z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </div>
                      <div className="my-subscription-body-info-list-info-sec">
                        <p>{t("current.subscription")}</p>
                        <h4>
                          {currentSubscription.data.current_plan.amount}{" "}
                          {currentSubscription.data.current_plan.type_formatted}
                        </h4>{" "}
                      </div>
                    </div>
                    <div className="my-subscription-body-info-list-card">
                      <div className="my-subscription-body-info-list-icon-sec">
                        {/* <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="#03363d"
                          data-name="Layer 1"
                          viewBox="0 0 24 24"
                        >
                          <path d="M21 23a1 1 0 11-2 0c0-2.206-1.794-4-4-4H9c-2.206 0-4 1.794-4 4a1 1 0 11-2 0c0-3.309 2.691-6 6-6h6c3.309 0 6 2.691 6 6zm1-15.5v2c0 .827-.673 1.5-1.5 1.5H20c0 2.206-1.794 4-4 4H8c-2.206 0-4-1.794-4-4h-.5C2.673 11 2 10.327 2 9.5v-2C2 6.673 2.673 6 3.5 6H4c0-2.206 1.794-4 4-4h3V1a1 1 0 112 0v1h3c2.206 0 4 1.794 4 4h.5c.827 0 1.5.673 1.5 1.5zM18 6c0-1.103-.897-2-2-2H8c-1.103 0-2 .897-2 2v5c0 1.103.897 2 2 2h8c1.103 0 2-.897 2-2V6zM9.5 7a1.5 1.5 0 10.001 3.001A1.5 1.5 0 009.5 7zm5 0a1.5 1.5 0 10.001 3.001A1.5 1.5 0 0014.5 7z"></path>
                        </svg> */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 20 24"
                        >
                          <path
                            fill="#03363D"
                            d="M17.7 23.611H2.5c-.526 0-.95-.454-.95-1.014 0-3.354 2.556-6.082 5.7-6.082h5.7c3.143 0 5.7 2.728 5.7 6.082 0 .56-.425 1.014-.95 1.014zm.475-17.14H17.7c0-2.236-1.705-4.055-3.8-4.055h-2.85V1.403c0-.56-.425-1.014-.95-1.014-.526 0-.95.454-.95 1.014v1.013H6.3c-2.096 0-3.8 1.819-3.8 4.055h-.475C1.239 6.47.6 7.153.6 7.99v2.028c0 .838.639 1.52 1.425 1.52H2.5c0 2.236 1.704 4.055 3.8 4.055h7.6c2.095 0 3.8-1.819 3.8-4.055h.475c.785 0 1.425-.682 1.425-1.52V7.99c0-.838-.64-1.52-1.425-1.52zM8.634 9.556a1.061 1.061 0 010 1.433.918.918 0 01-.672.297.918.918 0 01-.672-.297l-.515-.55-.516.55a.918.918 0 01-.672.297.918.918 0 01-.672-.297 1.061 1.061 0 010-1.433l.516-.55-.516-.551a1.061 1.061 0 010-1.434.907.907 0 011.344 0l.516.55.515-.55a.907.907 0 011.344 0 1.061 1.061 0 010 1.434l-.516.55.516.55zm6.65 0a1.061 1.061 0 010 1.433.918.918 0 01-.672.297.918.918 0 01-.671-.297l-.516-.55-.516.55a.918.918 0 01-.672.297.918.918 0 01-.671-.297 1.06 1.06 0 010-1.433l.515-.55-.515-.551a1.061 1.061 0 010-1.434.907.907 0 011.343 0l.516.55.515-.55a.907.907 0 011.344 0 1.061 1.061 0 010 1.434l-.516.55.516.55z"
                          ></path>
                        </svg>
                      </div>
                      <div className="my-subscription-body-info-list-info-sec">
                        <p>{t("current.bots")}</p>
                        <h4>
                          {currentSubscription.data.bots.used}/{" "}
                          {currentSubscription.data.bots.total}
                        </h4>
                      </div>
                    </div>
                    <div className="my-subscription-body-info-list-card">
                      <div className="my-subscription-body-info-list-icon-sec">
                        {/* <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="#03363d"
                          viewBox="0 0 24 24"
                        >
                          <path d="M24 11.247A12.012 12.012 0 1012.017 24H19a5.005 5.005 0 005-5v-7.753zM22 19a3 3 0 01-3 3h-6.983a10.041 10.041 0 01-7.476-3.343 9.917 9.917 0 01-2.476-7.814 10.043 10.043 0 018.656-8.761 10.564 10.564 0 011.3-.082A9.921 9.921 0 0118.4 4.3a10.041 10.041 0 013.6 7.042z"></path>
                          <path d="M8 9h4a1 1 0 000-2H8a1 1 0 000 2zM16 11H8a1 1 0 000 2h8a1 1 0 000-2zM16 15H8a1 1 0 000 2h8a1 1 0 000-2z"></path>
                        </svg> */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 21 20"
                        >
                          <path
                            fill="#03363D"
                            d="M3.13 2.93a9.997 9.997 0 0114.14 0c3.143 3.14 3.737 7.811 1.88 11.53l1.03 4.529a.822.822 0 01-.984.982l-4.53-1.03C8.078 22.23.2 17.47.2 9.998c0-2.67 1.04-5.18 2.93-7.067zm2.898 9.556h4.353a.821.821 0 100-1.642H6.028a.821.821 0 100 1.642zm0-3.336h8.344a.821.821 0 100-1.642H6.028a.821.821 0 100 1.642z"
                          ></path>
                        </svg>
                      </div>
                      <div className="my-subscription-body-info-list-info-sec">
                        <p>{t("current.messages")}</p>
                        <h4>
                          {currentSubscription.data.messages.used}/{" "}
                          {currentSubscription.data.messages.total}
                        </h4>{" "}
                      </div>
                    </div>
                    <div className="my-subscription-body-info-list-card">
                      <div className="my-subscription-body-info-list-icon-sec">
                        {/* <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="#03363d"
                          data-name="Layer 1"
                          viewBox="0 0 24 24"
                        >
                          <path d="M19 10H7V7h1.5C10.43 7 12 5.43 12 3.5S10.43 0 8.5 0h-5C1.57 0 0 1.57 0 3.5S1.57 7 3.5 7H5v3c-2.757 0-5 2.243-5 5v4c0 2.757 2.243 5 5 5h14c2.757 0 5-2.243 5-5v-4c0-2.757-2.243-5-5-5zM2 3.5C2 2.673 2.673 2 3.5 2h5c.827 0 1.5.673 1.5 1.5S9.327 5 8.5 5h-5C2.673 5 2 4.327 2 3.5zM5 12h14c1.654 0 3 1.346 3 3v3H2v-3c0-1.654 1.346-3 3-3zm14 10H5a3.004 3.004 0 01-2.828-2h19.656A3.004 3.004 0 0119 22zM9 15a1 1 0 112 0 1 1 0 01-2 0zm-4 0a1 1 0 112 0 1 1 0 01-2 0zm8 0a1 1 0 112 0 1 1 0 01-2 0zm4 0a1 1 0 112 0 1 1 0 01-2 0z"></path>
                        </svg> */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 23 22"
                        >
                          <path
                            fill="#03363D"
                            fillRule="evenodd"
                            d="M2.962 10.518h6.279c.855 0 1.55-.687 1.55-1.53V5.072c0-.974-.63-2.115-1.797-2.311a.483.483 0 01-.4-.557.49.49 0 01.565-.395 2.938 2.938 0 011.632.86V.593a2.705 2.705 0 00-2.84-.336 2.635 2.635 0 00-1.498 2.6 2.65 2.65 0 001.938 2.298c.26.072.412.337.34.594a.49.49 0 01-.602.336C6.678 5.686 5.613 4.423 5.48 2.942a3.516 3.516 0 01.006-.692 3.378 3.378 0 00-1.79 1.565 3.344 3.344 0 00-.065 3.034.48.48 0 01-.23.644.493.493 0 01-.653-.227 4.28 4.28 0 01-.392-1.34A2.404 2.404 0 00.933 7.372c-.297.85-.09 1.78.542 2.428.394.404.921.659 1.487.718zM6.03 6.405a.493.493 0 01.683-.11c.219.154.27.456.111.673a1.257 1.257 0 00-.096 1.335 1.29 1.29 0 001.147.688h.023c.267 0 .486.211.49.476a.486.486 0 01-.483.489h-.03c-.851 0-1.62-.46-2.014-1.207a2.21 2.21 0 01.17-2.344zm-3.674 9.67c.056-.46.186-.913.392-1.34a.493.493 0 01.653-.228.48.48 0 01.23.644c-.465.96-.44 2.095.066 3.035a3.38 3.38 0 001.79 1.564 3.521 3.521 0 01-.007-.692c.133-1.48 1.198-2.743 2.649-3.142a.49.49 0 01.602.335.482.482 0 01-.34.594 2.65 2.65 0 00-1.938 2.299 2.634 2.634 0 001.497 2.599 2.706 2.706 0 002.841-.336v-2.076a2.938 2.938 0 01-1.632.86.487.487 0 01-.564-.395.483.483 0 01.4-.557c1.166-.197 1.796-1.338 1.796-2.312v-3.914c0-.844-.695-1.53-1.55-1.53H2.965a2.423 2.423 0 00-1.49.717 2.348 2.348 0 00-.542 2.428 2.41 2.41 0 001.423 1.447zm3.505-2.823a2.265 2.265 0 012.044-1.207c.27.003.485.223.482.488a.486.486 0 01-.489.477h-.023a1.29 1.29 0 00-1.147.687c-.227.43-.19.942.096 1.336a.478.478 0 01-.112.673.49.49 0 01-.682-.11 2.21 2.21 0 01-.17-2.344zm8.067 8.256h-2.159v-5.132c.128.01.257.014.386.014 3.015 0 5.46-2.413 5.46-5.388 0-2.976-2.445-5.388-5.46-5.388-.13 0-.258.004-.386.013V.495h2.159l.422 2.113 2.266.928 1.813-1.2 2.506 2.474L19.72 6.6l.94 2.236 2.14.416v3.499l-2.14.417-.94 2.236 1.216 1.79-2.506 2.474-1.813-1.2-2.266.927-.422 2.113z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </div>
                      <div className="my-subscription-body-info-list-info-sec">
                        <p>{t("current.fine_tune")}</p>
                        <h4>
                          {currentSubscription.data.fine_tunings.used}/{" "}
                          {currentSubscription.data.fine_tunings.total}
                        </h4>
                      </div>
                    </div>
                    <div className="my-subscription-body-info-list-card">
                      <div className="my-subscription-body-info-list-icon-sec">
                        {/* <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="#03363d"
                          data-name="Layer 1"
                          viewBox="0 0 24 24"
                        >
                          <path d="M19 2h-1V1a1 1 0 00-2 0v1H8V1a1 1 0 00-2 0v1H5C2.243 2 0 4.243 0 7v12c0 2.757 2.243 5 5 5h14c2.757 0 5-2.243 5-5V7c0-2.757-2.243-5-5-5zM5 4h14c1.654 0 3 1.346 3 3v1H2V7c0-1.654 1.346-3 3-3zm14 18H5c-1.654 0-3-1.346-3-3v-9h20v9c0 1.654-1.346 3-3 3zm-3-6a1 1 0 01-1 1H9a1 1 0 010-2h6a1 1 0 011 1z"></path>
                        </svg> */}
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="none"
                            viewBox="0 0 25 24"
                          >
                            <path
                              fill="#03363D"
                              d="M.4 8V7c0-2.757 2.243-5 5-5h1V1a1 1 0 112 0v1h8V1a1 1 0 112 0v1h1c2.757 0 5 2.243 5 5v1H.4zm24 2v9c0 2.757-2.243 5-5 5h-14c-2.757 0-5-2.243-5-5v-9h24zm-12 9a1 1 0 00-1-1h-5a1 1 0 100 2h5a1 1 0 001-1zm7-4a1 1 0 00-1-1h-12a1 1 0 100 2h12a1 1 0 001-1z"
                            ></path>
                          </svg>
                      </div>
                      <div className="my-subscription-body-info-list-info-sec">
                        <p>{t("current.expiry")}</p>
                        <h4>
                          {currentSubscription.data.current_plan.expiry_date}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <div className="my-subscription-body-sec">
            <Tab.Container id="left-tabs-example" defaultActiveKey="monthly">
              <Row>
                <Col sm={12}>
                  <div className="my-subscription-upgrade-tab-header-sec">
                    <div className="my-subscription-upgrade-tab-header-info-left-sec">
                      <h3>{t("upgrade.heading")}</h3>
                      <p>{t("upgrade.message")}</p>
                      <div className="my-subscription-upgrade-tab-nav-link-sec mt-3">
                        <Nav variant="pills">
                          <Nav.Item>
                            <Nav.Link eventKey="monthly">{t("monthly")}</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="yearly">
                              <h4>{t("yearly")}</h4>
                              {/* <p>Save 20%</p> */}
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                    </div>
                    </div>
                  </div>
                </Col>
                <Col sm={12}>
                  <div className="my-subscription-upgrade-tab-content-sec">
                    <Tab.Content>
                      <Tab.Pane eventKey="monthly">
                        <div className="my-subscription-upgrade-tab-content">
                          <Row>
                            <Col md={12} lg={12} xl={8}>
                              <div className="onboarding-subscription-plans-box">
                                {subscriptionPlans.loading ? (
                                  [...Array(3)].map((i) => (
                                    <div className="onboarding-form">
                                      <div className="onboarding-body-sec">
                                        <Row className="justify-content-md-center">
                                          <Col md={12}>
                                            <div className="mb-3">
                                              <Skeleton count={1} height={30} />
                                            </div>
                                            <div className="mb-3">
                                              <Skeleton
                                                count={1}
                                                height={20}
                                                width={100}
                                              />
                                              <Skeleton count={1} height={40} />
                                            </div>
                                            <div className="mb-3">
                                              <Skeleton
                                                count={1}
                                                height={150}
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                    </div>
                                  ))
                                ) : Object.keys(subscriptionPlans.data).length >
                                    0 &&
                                  subscriptionPlans.data.subscription_plans
                                    .length > 0 ? (
                                  subscriptionPlans.data.subscription_plans.map(
                                    (plan) => (
                                      <>
                                        <SubscriptionCard
                                          plan={plan}
                                          handleStripeIntent={
                                            handleStripeIntent
                                          }
                                          type="monthly"
                                        />
                                      </>
                                    )
                                  )
                                ) : (
                                  <NoDataFound />
                                )}
                              </div>
                            </Col>
                            <Col md={12} lg={12} xl={4}>
                              {currentSubscription.loading ? (
                                <div className="onboarding-form">
                                  <div className="onboarding-body-sec">
                                    <Row className="justify-content-md-center">
                                      <Col md={10}>
                                        <div className="mb-3">
                                          <Skeleton
                                            count={1}
                                            height={10}
                                            width={100}
                                          />
                                          <Skeleton count={1} height={40} />
                                        </div>
                                        <div className="mb-3">
                                          <Skeleton
                                            count={1}
                                            height={10}
                                            width={100}
                                          />
                                          <Skeleton count={1} height={40} />
                                        </div>
                                        <div className="mb-3">
                                          <Skeleton
                                            count={1}
                                            height={10}
                                            width={100}
                                          />
                                          <Skeleton count={1} height={150} />
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              ) : Object.keys(currentSubscription.data).length >
                                  0 &&
                                Object.keys(
                                  currentSubscription.data.current_plan
                                ).length > 0 ? (
                                <UpcomingBills
                                  data={currentSubscription.data}
                                  setEditAddress={setEditAddress}
                                />
                              ) : null}
                            </Col>
                          </Row>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="yearly">
                        <div className="my-subscription-upgrade-tab-content">
                          <Row>
                            <Col md={8}>
                              <div className="onboarding-subscription-plans-box">
                                {subscriptionPlans.loading ? (
                                  <div className="onboarding-form">
                                    <div className="onboarding-body-sec">
                                      <Row className="justify-content-md-center">
                                        <Col md={10}>
                                          <div className="mb-3">
                                            <Skeleton
                                              count={1}
                                              height={10}
                                              width={100}
                                            />
                                            <Skeleton count={1} height={40} />
                                          </div>
                                          <div className="mb-3">
                                            <Skeleton
                                              count={1}
                                              height={10}
                                              width={100}
                                            />
                                            <Skeleton count={1} height={40} />
                                          </div>
                                          <div className="mb-3">
                                            <Skeleton
                                              count={1}
                                              height={10}
                                              width={100}
                                            />
                                            <Skeleton count={1} height={150} />
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  </div>
                                ) : Object.keys(subscriptionPlans.data).length >
                                    0 &&
                                  subscriptionPlans.data.subscription_plans
                                    .length > 0 ? (
                                  subscriptionPlans.data.subscription_plans.map(
                                    (plan) => (
                                      <>
                                        <SubscriptionCard
                                          plan={plan}
                                          handleStripeIntent={
                                            handleStripeIntent
                                          }
                                          type="yearly"
                                        />
                                      </>
                                    )
                                  )
                                ) : (
                                  <NoDataFound />
                                )}
                              </div>
                            </Col>
                            <Col md={4}>
                              {currentSubscription.loading ? (
                                <div className="onboarding-form">
                                  <div className="onboarding-body-sec">
                                    <Row className="justify-content-md-center">
                                      <Col md={10}>
                                        <div className="mb-3">
                                          <Skeleton
                                            count={1}
                                            height={10}
                                            width={100}
                                          />
                                          <Skeleton count={1} height={40} />
                                        </div>
                                        <div className="mb-3">
                                          <Skeleton
                                            count={1}
                                            height={10}
                                            width={100}
                                          />
                                          <Skeleton count={1} height={40} />
                                        </div>
                                        <div className="mb-3">
                                          <Skeleton
                                            count={1}
                                            height={10}
                                            width={100}
                                          />
                                          <Skeleton count={1} height={150} />
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              ) : Object.keys(currentSubscription.data).length >
                                  0 &&
                                Object.keys(
                                  currentSubscription.data.current_plan
                                ).length > 0 ? (
                                <UpcomingBills
                                  data={currentSubscription.data}
                                  setEditAddress={setEditAddress}
                                />
                              ) : null}
                            </Col>
                          </Row>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </div>
      </div>
      {editAddress && (
        <EditAddressModal
          address={currentSubscription.data.user_billing_address}
          addBot={editAddress}
          closeAddBotModal={closeEditAddressModal}
          setAddBot={setEditAddress}
        />
      )}
    </>
  );
};

export default withTranslation(SubscriptionIndex);
