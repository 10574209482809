import { call, select, put, takeLatest, all } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from '../index';
import {
  changePasswordFailure,
  changePasswordSuccess,
  dashboardFailure,
  dashboardSuccess,
  emailVerifyFailure,
  emailVerifySuccess,
  forgotPasswordFailure,
  forgotPasswordSuccess,
  generateAPIKeyFailure,
  generateAPIKeySuccess,
  loginFailure,
  loginSuccess,
  logoutFailure,
  logoutSuccess,
  profileFailure,
  profileSuccess,
  registerFailure,
  registerSuccess,
  resendOtpFailure,
  resendOtpSuccess,
  resetPasswordFailure,
  resetPasswordSuccess,
  settingsJsonFailure,
  settingsJsonSuccess,
  twoStepAuthFailure,
  twoStepAuthSuccess,
  updateProfileFailure,
  updateProfileSuccess,
  updateTwoStepFailure,
  updateTwoStepSuccess,
  viewAPIKeyFailure,
  viewAPIKeySuccess,
  updateLiveChatConfigurationFailure,
  updateLiveChatConfigurationSuccess,
  selectedBotSuccess,
  verifyForgotPasswordSuccess,
  verifyForgotPasswordFailure,
  deleteProfileSuccess,
  deleteProfileFailure,
  sendInstallationDocumentsSuccess,
  sendInstallationDocumentsFailure,
  updateEmailCodeSuccess,
  updateEmailCodeFailure,
  updateEmailSuccess,
  updateEmailFailure,
  updateTourSuccess,
  updateTourFailure,
} from "../slices/AdminSlice";
import {
  botsListSuccess,
} from "../slices/BotSlice";


function* loginAPI(action) {
  yield getCommonSaga({
    apiUrl: "login",
    payload: action.payload,
    successCallback: true,
    success: data => {
      store.dispatch(loginSuccess({ ...data.user, remember: action.payload.remember }));
      store.dispatch(selectedBotSuccess({}));
      store.dispatch(botsListSuccess({}));
      if (data.user.email_status === 1 && data.user.tfa_status === 0) {
        if (action.payload.remember) localStorage.setItem("token", data.access_token);
        else sessionStorage.setItem("token", data.access_token);
        store.dispatch(profileSuccess(data.user));
      }
    },
    failure: loginFailure,
  });
}

function* registerAPI(action) {
  yield getCommonSaga({
    apiUrl: "register",
    payload: action.payload,
    successCallback: true,
    success: data => {
      store.dispatch(registerSuccess({ ...data.user, remember: action.payload.remember }));
      store.dispatch(selectedBotSuccess({}));
      store.dispatch(botsListSuccess({}));
      if (data.user.email_status === 1) {
        if (action.payload.remember) localStorage.setItem("token", data.access_token);
        else sessionStorage.setItem("token", data.access_token);
        store.dispatch(profileSuccess(data.user));
      }
    },
    failure: registerFailure,
  });
}

function* forgotPasswordAPI(action) {
  yield getCommonSaga({
    apiUrl: "forgot_password",
    payload: action.payload,
    success: forgotPasswordSuccess,
    failure: forgotPasswordFailure,
  });
}

function* verifyForgotPasswordAPI(action) {
  yield getCommonSaga({
    apiUrl: "verify_forgot_password_code",
    payload: action.payload,
    success: verifyForgotPasswordSuccess,
    failure: verifyForgotPasswordFailure,
  });
}

function* resetPasswordAPI(action) {
  yield getCommonSaga({
    apiUrl: "reset_password",
    payload: action.payload,
    success: resetPasswordSuccess,
    failure: resetPasswordFailure,
  });
}

function* profileAPI(action) {
  yield getCommonSaga({
    apiUrl: "profile",
    payload: action.payload,
    successNotify: false,
    successCallback: true,
    success: data => store.dispatch(profileSuccess(data.user)),
    failure: profileFailure,
  });
}

function* updateProfileAPI(action) {
  yield getCommonSaga({
    apiUrl: "update_profile",
    payload: action.payload,
    successCallback: true,
    success: data => {
      store.dispatch(profileSuccess(data.user));
      store.dispatch(updateProfileSuccess(data));
    },
    failure: updateProfileFailure,
  });
}

function* updateEmailCodeAPI(action) {
  yield getCommonSaga({
    apiUrl: "update_email_verification_code",
    payload: action.payload,
    success: updateEmailCodeSuccess,
    failure: updateEmailCodeFailure,
  });
}

function* updateEmailAPI(action) {
  yield getCommonSaga({
    apiUrl: "update_email",
    payload: action.payload,
    successCallback: true,
    success: data => {
      store.dispatch(profileSuccess(data.user));
      store.dispatch(updateEmailSuccess(data));
    },
    failure: updateEmailFailure,
  });
}

function* deleteProfileAPI(action) {
  yield getCommonSaga({
    apiUrl: "delete_account",
    payload: action.payload,
    success: deleteProfileSuccess,
    failure: deleteProfileFailure,
  });
}

function* dashboardAPI(action) {
  yield getCommonSaga({
    apiUrl: "chats/dashboard",
    payload: action.payload,
    successNotify: false,
    success: dashboardSuccess,
    failure: dashboardFailure,
  });
}

function* generateAPIKeyAPI(action) {
  yield getCommonSaga({
    apiUrl: "generate_api_key",
    payload: action.payload,
    successNotify: false,
    successCallback: true,
    success: data => {
      store.dispatch(generateAPIKeySuccess(data));
      store.dispatch(viewAPIKeySuccess(data));
    },
    failure: generateAPIKeyFailure,
  });
}

function* viewAPIKeyAPI(action) {
  yield getCommonSaga({
    apiUrl: "view_api_key",
    payload: action.payload,
    successNotify: false,
    success: viewAPIKeySuccess,
    failure: viewAPIKeyFailure,
  });
}

function* changePasswordAPI(action) {
  yield getCommonSaga({
    apiUrl: "change_password",
    payload: action.payload,
    successCallback: true,
    success: data => store.dispatch(changePasswordSuccess({ data: data })),
    failure: changePasswordFailure,
  });
}

function* settingsJsonAPI(action) {
  yield getCommonSaga({
    apiUrl: "get_settings_json",
    payload: action.payload,
    successNotify: false,
    success: settingsJsonSuccess,
    failure: settingsJsonFailure,
  });
}

function* emailVerifyAPI(action) {
  yield getCommonSaga({
    apiUrl: "verify_email",
    payload: action.payload,
    successCallback: true,
    success: data => {
      if (action.payload.remember) localStorage.setItem("token", data.access_token);
      else sessionStorage.setItem("token", data.access_token);
      store.dispatch(emailVerifySuccess(data.user));
      store.dispatch(profileSuccess(data.user));
    },
    failure: emailVerifyFailure,
  });
}

function* twoStepAuthAPI(action) {
  yield getCommonSaga({
    apiUrl: "tfa_login",
    payload: action.payload,
    successCallback: true,
    success: data => {
      if (action.payload.remember) localStorage.setItem("token", data.access_token);
      else sessionStorage.setItem("token", data.access_token);
      store.dispatch(twoStepAuthSuccess(data.user));
      store.dispatch(profileSuccess(data.user));
    },
    failure: twoStepAuthFailure,
  });
}

function* updateTwoStepAPI(action) {
  yield getCommonSaga({
    apiUrl: "tfa_status",
    payload: action.payload,
    successCallback: true,
    success: data => {
      store.dispatch(updateTwoStepSuccess(data));
      store.dispatch(profileSuccess(data.user));
    },
    failure: updateTwoStepFailure,
  });
}

function* resendOtpAPI(action) {
  yield getCommonSaga({
    apiUrl: "send_verification_code",
    payload: action.payload,
    success: resendOtpSuccess,
    failure: resendOtpFailure,
  });
}

function* logoutAPI(action) {
  yield getCommonSaga({
    apiUrl: "logout",
    payload: action.payload,
    successNotify: false,
    errorNotify: false,
    successCallback: true,
    success: data => {
      store.dispatch(logoutSuccess(data));
      store.dispatch(selectedBotSuccess({}));
      store.dispatch(botsListSuccess({}));
    },
    failure: logoutFailure,
  });
}

function* updateLiveChatConfigurationAPI(action) {
  yield getCommonSaga({
    apiUrl: "lc_configurations",
    payload: action.payload,
    successCallback: true,
    success: data => {
      store.dispatch(profileSuccess(data.user));
      store.dispatch(updateLiveChatConfigurationSuccess(data));
    },
    failure: updateLiveChatConfigurationFailure,
  });
}

function* sendInstalltionDocumentsAPI(action) {
  yield getCommonSaga({
    apiUrl: "widget_document",
    payload: action.payload,
    success: sendInstallationDocumentsSuccess,
    failure: sendInstallationDocumentsFailure,
  });
}

function* updateTourAPI(action) {
  yield getCommonSaga({
    apiUrl: "update_profile",
    payload: action.payload,
    successNotify: false,
    successCallback: true,
    success: data => {
      store.dispatch(profileSuccess(data.user));
      store.dispatch(updateTourSuccess(data));
    },
    failure: updateTourFailure,
  });
}


export default function* AdminSaga() {
  yield all([
    yield takeLatest('admin/loginStart', loginAPI),
    yield takeLatest('admin/registerStart', registerAPI),
    yield takeLatest('admin/forgotPasswordStart', forgotPasswordAPI),
    yield takeLatest('admin/verifyForgotPasswordStart', verifyForgotPasswordAPI),
    yield takeLatest('admin/resetPasswordStart', resetPasswordAPI),
    yield takeLatest('admin/profileStart', profileAPI),
    yield takeLatest('admin/updateProfileStart', updateProfileAPI),
    yield takeLatest('admin/updateEmailCodeStart', updateEmailCodeAPI),
    yield takeLatest('admin/updateEmailStart', updateEmailAPI),
    yield takeLatest('admin/deleteProfileStart', deleteProfileAPI),
    yield takeLatest('admin/dashboardStart', dashboardAPI),
    yield takeLatest('admin/generateAPIKeyStart', generateAPIKeyAPI),
    yield takeLatest('admin/viewAPIKeyStart', viewAPIKeyAPI),
    yield takeLatest('admin/changePasswordStart', changePasswordAPI),
    yield takeLatest('admin/settingsJsonStart', settingsJsonAPI),
    yield takeLatest('admin/emailVerifyStart', emailVerifyAPI),
    yield takeLatest('admin/twoStepAuthStart', twoStepAuthAPI),
    yield takeLatest('admin/updateTwoStepStart', updateTwoStepAPI),
    yield takeLatest('admin/resendOtpStart', resendOtpAPI),
    yield takeLatest('admin/logoutStart', logoutAPI),
    yield takeLatest('admin/updateLiveChatConfigurationStart', updateLiveChatConfigurationAPI),
    yield takeLatest('admin/sendInstallationDocumentsStart', sendInstalltionDocumentsAPI),
    yield takeLatest('admin/updateTourStart', updateTourAPI),
  ]);
}

